<form #policyInquiryForm *ngIf="searchForm && propertySearchForm" aria-live="assertive">
    <bolt-tabset borderless activetab="{{ activeTab }}" class="noprint">
        <bolt-tablabel *ngIf="authorizationProperty != 0" (click)="navigateToPropertyAuto('property')" selected="false">Property</bolt-tablabel>
        <bolt-tabpanel open="false" *ngIf="submitOptions && propertySearchForm">
            <div>
                <app-search-error-panel [hasError]="hasError" [errorMessage]="errorMessage">
                </app-search-error-panel>
            </div>
            <h2 class="bolt-heading-md">Policy Inquiry</h2>
            <tpi-property-search [submitOptions]="submitOptions" [parentFormGroup]="propertySearchForm"></tpi-property-search>
        </bolt-tabpanel>
        <bolt-tablabel *ngIf="authorizationAuto != 0" (click)="navigateToPropertyAuto('auto')">Auto</bolt-tablabel>
        <bolt-tabpanel *ngIf="authorizationAuto != 0 && searchForm">
            <app-search-error-panel [hasError]="hasError" [errorMessage]="errorMessage"></app-search-error-panel>
            <h2 class="bolt-heading-md">Policy Inquiry</h2>
            <div [formGroup]="searchForm" class="bolt-space-bottom-lg">
                <bolt-radio-group label="Do you have the policy number?" ngDefaultControl [formControlName]="'havingPolicyNumber'" value="1" horizontal optionaltext="hide"
                    (change)="selectionChange()">
                    <bolt-radio value="1">Yes</bolt-radio>
                    <bolt-radio value="2">No</bolt-radio>
                </bolt-radio-group>
            </div>
            <div>
                <app-auto-search [submitOptions]="submitOptions" *ngIf="searchForm.get('havingPolicyNumber').value == '1'" [parentForm]="searchForm"></app-auto-search>
                <app-auto-no-search [submitOptions]="submitOptions" *ngIf="searchForm.get('havingPolicyNumber').value == '2'" [parentForm]="searchForm" (autoNoSearchEmit)="setAutoSearch($event)"
                    [(isResetNoSearch)]="isResetNoAutoSearch"></app-auto-no-search>
            </div>
        </bolt-tabpanel>
    </bolt-tabset>
    <app-search-results [showAutoResult]="showAutoResult" [showPropertyResult]="showPropertyResult" (showAutoResultEmit)="displayResult($event)"
        (showPropertySearchEmit)="displayResultProperty($event)" (editFlowError)="editLienHolderFailure($event)">
    </app-search-results>
    <app-button-bar #buttonBar class="noprint" [isActiveTab]="selectedTab" [searchFormValues]="getSearchForm()" [(disablePrint)]="disablePrint" (resetSearchForm)="resetForm($event)"
        (showResults)="showResults($event)" (performValidationEmit)="performValidation($event)" (setSearch)="setSearch($event)">
    </app-button-bar>
</form>
<bolt-waiting-indicator id="policyRedirectWait" fullscreen starthidden></bolt-waiting-indicator>