export const environmentCommon = {
    requestTimeoutInMillsec: 20000,
    idleTimeoutInMinutes: 19,
    errorFocusDelayInMillsec: 500,
    defaultRouteIsAdmin: false,
    enableAdminRoutes: false,
    apigeeEnvironment: 'https://api-test.nwie.net/',
    production: false,
    arrayElementCountLogging: 50,
    splunkProps: {
        token: '09742F15-8F11-4F38-8E42-A23D98434C7B',
        appName: 'third-party-inquiry'
    },

    CLIENT_LOGGING_API: {
        ENDPOINT:
            'https://api-test.nwie.net/it-management/client-logging/v1/client-logs-cloud',
        PUSH_LENGTH: 5,
        maxLogLength: 190000,
        maxRetryAttempts: 5
    },

    authorize: {
        client_id: 'QvRBDarCVPoGfylIGAPsxxCFmPQf7Fgj'
    },
    GETDISTRIBUTIONPARTNER_INFO: {
        url: (agentIndentifier: string, query: string): string =>
            `https://api-int-test.nwie.net/thirdpartyinquiryexperience_1/distribution-partner-information-management/v3/legacies/${agentIndentifier}/namecontactpoints?${query}`
    },

    GETSA360_INFO: {
        url: (agentIndentifier: string, query: string): string => `https://api-test.nwie.net/corecomponentexperience_1/distribution-partner-information-management/v3/distribution-partner-roles/${agentIndentifier}/contractSummaries?${query}`
    },

    LOGGING: {
        level: 'debug' /* debug, info, warn, err */,
        logJSON: 'OBJECTS' /* can be true, false, 'OBJECTS', or 'SHORT' */,
        logToConsole: true
    },
    // eslint-disable-next-line tpi/no-hardcoded-nw-url
    contractsEndorsementsURL: 'https://www.isp.t.nationwide.com/#/insurancecontracts',
    APIs: {
        apigeeEnvironment: 'https://api-test.nwie.net/',
        experience: 'thirdpartyinquiryexperience_1/auto-policies/v1/policies/',
        experienceAuto: 'thirdpartyinquiryexperience_1/auto-policies/v1/policies/',
        experienceAutoServicing:
            'thirdpartyinquiryexperience_1/auto-policy-change/v1/policy-changes/',
        autoPolicies: 'thirdpartyinquiryexperience_1/auto-policies/v1/policies/',
        autoPoliciesServicing:
            'thirdpartyinquiryexperience_1/auto-policy-change/v1/policy-changes/',
        sourceSystem: 'PolicyCenter',
        mortgageCompanies:
            'thirdpartyinquiryexperience_1/mortgage-companies/v1/mortgage-companies',
        experienceHomeowner:
            'thirdpartyinquiryexperience_1/homeowner-policies/v1/policies',
        experienceHomeownerServicing:
            'thirdpartyinquiryexperience_1/homeowner-policy-change/v1/policy-changes',
        experienceHomeownerRenewalServicing:
            'thirdpartyinquiryexperience_1/homeowner-policy-change/v1/renewals',
        experienceDwellingFire:
            'thirdpartyinquiryexperience_1/dwelling-fire-policies/v1/policies',
        experienceDwellingFireServicing:
            'thirdpartyinquiryexperience_1/dwellingfire-policy-change/v1/policy-changes',
        experienceDwellingFireRenewalServicing:
            'thirdpartyinquiryexperience_1/dwellingfire-policy-change/v1/renewals',
        experienceCondo:
            'thirdpartyinquiryexperience_1/condominium-policies/v1/policies',
        experienceCondoServicing:
            'thirdpartyinquiryexperience_1/condominium-policy-change/v1/policy-changes',
        experienceCondoRenewalServicing:
            'thirdpartyinquiryexperience_1/condominium-policy-change/v1/renewals',
        apigeeExtHostnameURL: 'https://api-test.nwie.net/',
        bulkUploadApi: 'thirdpartyinquiryexperience_1/multiprocessing/v1/entries',
        passwordChange: 'thirdpartyinquiryexperience_1/multiprocessing/v1/usermanagement/changepassword',
        passwordUpdate: 'thirdpartyinquiryexperience_1/multiprocessing-unsecure/v1/update-password',
        expiredPassword: 'thirdpartyinquiryexperience_1/multiprocessing-unsecure/v1/usermanagement/expiredpassword',
        retrieveUsersByCompany: 'thirdpartyinquiryexperience_1/multiprocessing/v1/user-management/users',
        retrieveCompanies: 'thirdpartyinquiryexperience_1/multiprocessing/v1/user-management/read-company-list',
        userManagement: 'thirdpartyinquiryexperience_1/multiprocessing/v1/user-management',
        createNewUser: '/thirdpartyinquiryexperience_1/multiprocessing/v1/user-management/create-user',
        manageUsers: '/thirdpartyinquiryexperience_1/multiprocessing/v1/user-management/manage',
        editUser: '/thirdpartyinquiryexperience_1/multiprocessing/v1/user-management/edit',
        forgotPasswordRequestMFA: 'thirdpartyinquiryexperience_1/multiprocessing-unsecure/v1/usermanagement/sendconfirmation',
        forgotPasswordVerifyMFA: 'thirdpartyinquiryexperience_1/multiprocessing-unsecure/v1/usermanagement/verifyconfirmation',
        forgotPasswordRetrieveEmail: 'thirdpartyinquiryexperience_1/multiprocessing-unsecure/v1/usermanagement/validateuserunlocked',
        webSupportUsers: 'thirdpartyinquiryexperience_1/multiprocessing/v1/user-management/filter/users',
        webSupportDeleteCompany: 'thirdpartyinquiryexperience_1/multiprocessing/v1/user-management/delete-company',
        webSupportEditCompany: 'thirdpartyinquiryexperience_1/multiprocessing/v1/user-management/update-company',
        webSupportCreateCompany: 'thirdpartyinquiryexperience_1/multiprocessing/v1/user-management/create-company',
        termsAndConditions: 'thirdpartyinquiryexperience_1/multiprocessing/v1/user-management/terms-and-conditions',
        userLastLogin: 'thirdpartyinquiryexperience_1/multiprocessing/v1/user-management/user-last-login',
        userInfo: 'thirdpartyinquiryexperience_1/multiprocessing/v1/user-info'
    },
    awdWorkflow: {
        endpoint: 'https://api-int-test.nwie.net/thirdpartyinquiryexperience_1/workflow-provider/awd-workflow/v1/work-items',
        awdWorkflowTargetHost: 'ST4'
    },
    policyCenterShortName: 'pls15',
    PERSONAL_LINES_BILLING_API_HOST: 'https://api-test.nwie.net',
    PERSONAL_BILLING_ROOT: '/thirdpartyinquiryexperience_1/personal-lines-billing/v1',
    connectionType: 'CIB1',
    gisEndPoint: 'https://api-int-test.nwie.net/thirdpartyinquiryexperience_1/place/v1/address',

    ecif: 'thirdpartyinquiryexperience_1/enterprise-customer/v1/',
    ecifCustomerType: 'A',
    ecifReturnEmail: 'Y',
    ecifDepth: '3',
    ecifValidateAutoPolicyVin: 'thirdpartyinquiryexperience_1/enterprise-customer/v1/validation/search/auto/policy-vin',
    ecifValidateAutoAddressEcn: 'thirdpartyinquiryexperience_1/enterprise-customer/v1/validation/search/auto/address/ecn',
    ecifValidateAutoAddressEcnpolicyNumber: 'thirdpartyinquiryexperience_1/enterprise-customer/v1/validation/search/auto/address/:ecn/policy-numbers',
    ecifvalidateAutoAddressVin: 'thirdpartyinquiryexperience_1/enterprise-customer/v1/validation/search/auto/vin/:policyNumber',
    tpiSession: 'thirdpartyinquiryexperience_1/context-cache/v1/contexts/',
    enterpriseUserAuth: 'thirdpartyinquiryexperience_1/enterprise-user-auth/v1/token',
    // eslint-disable-next-line tpi/no-hardcoded-nw-url
    ensightenBootstrap: '//tags.nationwide.com/test/Bootstrap.js',
    // eslint-disable-next-line tpi/no-hardcoded-nw-url
    pingLogoutUrl: 'https://identity-pt.nationwide.com/idp/startSLO.ping',
    PING: {
        // eslint-disable-next-line tpi/no-hardcoded-nw-url
        redirectUri: 'https://tpi.t.nationwide.com/landing',
        // eslint-disable-next-line tpi/no-hardcoded-nw-url
        logoutUrl: 'https://tpi.t.nationwide.com/logout',
        authorizeUrl: 'https://api-test.nwie.net/security-processing/enterprise-user-auth/v2/authorize',
        auth_id_spid: 'tpi-test'
    },
    sa360Contact: '800-282-1446',
    nonSA360Contact: '800-228-9630'
};
